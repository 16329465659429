import {
  Box,
  Button,
  Checkbox,
  Divider,
  Group,
  Paper,
  Radio,
  Select,
  Text,
  TextInput,
  useMantineTheme
} from '@mantine/core';
import { useListState } from '@mantine/hooks';
import { ContextModalProps } from '@mantine/modals';
import { IconSearch } from '@tabler/icons-react';
import _compact from 'lodash/compact';
import _groupBy from 'lodash/groupBy';
import _uniq from 'lodash/uniq';
import { useEffect, useMemo, useState } from 'react';

import MetricsGroupLabel from '@/core/components/atoms/metrics-group-label/metrics-group-label';
import { Z_INDEX } from '@/core/constants/z-index.constants';
import { useCurrentProject } from '@/core/hooks/query-hooks/use-current-project/use-current-project';
import { MetricsGroupId } from '@/core/hooks/use-metrics-group/use-metrics-group';
import { useModals } from '@/core/hooks/use-modals/use-modals';
import { components } from '@/core/types/api';
import { UnitFormatMap } from '@/core/utils/unit-conversions/unit-format-mapping';
import {
  ExportFileType,
  useExport
} from '@/observe/hooks/query-hooks/use-export/use-export';
import { getSortBy } from '@/observe/hooks/query-hooks/use-rows/use-rows';
import { useQueryParameters } from '@/observe/hooks/use-query-parameters/use-query-parameters';
import { useObserveStore } from '@/observe/stores/observe.store';
import { usePersistedObserveStore } from '@/observe/stores/persisted-observe.store';
import { ColumnConfig } from '@/observe/types/observe-store.types';
import { getTimeRange } from '@/observe/utils/get-time-range/get-time-range';
import { isFieldEnabled } from '@/observe/utils/is-field-enabled/is-field-enabled';

const FILE_TYPES: ExportFileType[] = ['csv', 'jsonl'];

const EXPORT_MODE = {
  SELECT_COLUMNS: 'select_columns',
  TEST_SET: 'test_set'
} as const;

type ExportMode = (typeof EXPORT_MODE)[keyof typeof EXPORT_MODE];

const ObserveExportModal = ({
  innerProps: { columnsConfig }
}: ContextModalProps<{ columnsConfig: { [key: string]: ColumnConfig } }>) => {
  // Local State
  const [isExporting, setIsExporting] = useState(false);
  const [exportMode, setExportMode] = useState<ExportMode>(
    EXPORT_MODE.SELECT_COLUMNS
  );
  const [customFileName, setCustomFileName] = useState('');
  const [fileType, setFileType] = useState<ExportFileType>(FILE_TYPES[0]);
  const [searchQuery, setSearchQuery] = useState('');

  const hiddenColumns = usePersistedObserveStore((s) => s.hiddenColumns);

  const { data: currentProject } = useCurrentProject();

  const theme = useMantineTheme();

  const defaultFileName = useMemo(
    () =>
      `${currentProject?.name ?? 'project'} export on ${UnitFormatMap[
        'parsed__datetime'
      ](new Date())
        .toString()
        .replace(',', ' at')}`,
    [currentProject]
  );

  const fileName = customFileName !== '' ? customFileName : defaultFileName;

  // Global State
  const selectedMetricsRows = useObserveStore((s) => s.selectedMetricsRows);
  const { get } = useQueryParameters();
  const promptMonitorFilters = get('promptMonitorFilters');
  const sortBy = get('sortBy');
  const sortDirection = get('sortDirection');
  const timeRange = get('timeRange');

  const lastUpdatedTime = useObserveStore((s) => s.lastUpdatedTime);

  // Hooks
  const { closeAll } = useModals();
  const exportData = useExport();

  let filters = [...(promptMonitorFilters ?? [])];

  // Note: to get the API to export all nested rows in a chain, we filter by the chain root id (not the individual row id)
  if (selectedMetricsRows != null && selectedMetricsRows.length > 0) {
    filters.push({
      col_name: 'chain_root_id',
      operator: 'in',
      value: _uniq(
        _compact(
          selectedMetricsRows.map(({ chain_root_id }) => chain_root_id)
        ) as string[]
      )
    });
  }

  const visibleColumnConfigs: ColumnConfig[] = [];
  for (const [columnId, columnConfig] of Object.entries(columnsConfig)) {
    const {
      isCustomMetric,
      projectSettingAccessor,
      nliProjectSettingAccessor
    } = columnConfig;

    const isEnabled = isFieldEnabled({
      fieldId: columnId,
      isCustomMetric,
      projectSettingAccessor,
      nliProjectSettingAccessor,
      registeredScorersConfiguration:
        currentProject?.settings?.registered_scorers_configuration || undefined,
      scorersConfiguration:
        currentProject?.settings?.scorers_configuration || undefined
    });

    if (!isEnabled) {
      continue;
    }

    visibleColumnConfigs.push(columnConfig);
  }

  // Default all columns to selected
  const defaultColumnSelectionState: {
    columnConfig: ColumnConfig;
    checked: boolean;
  }[] = visibleColumnConfigs.map((columnConfig) => ({
    columnConfig,
    checked: true
  }));

  const [columnSelectionState, columnSelectionHandlers] = useListState(
    defaultColumnSelectionState
  );

  // Update the list state if more column configurations load
  useEffect(() => {
    if (defaultColumnSelectionState.length !== columnSelectionState.length) {
      columnSelectionHandlers.setState(defaultColumnSelectionState);
    }
  }, [defaultColumnSelectionState]);

  const indexedColumnSelectionState = columnSelectionState.map(
    (value, index) => ({
      ...value,
      index
    })
  );

  const groupedColumnSelectionState = _groupBy(
    indexedColumnSelectionState,
    (value) => value.columnConfig.metricsGroupId
  );

  const allCheckboxes = Object.entries(groupedColumnSelectionState).map(
    ([metricsGroupId, columnSelectionStateGroup]) => {
      const checkboxes = columnSelectionStateGroup.map(
        ({ columnConfig, checked, index }) => (
          <Checkbox
            checked={checked}
            key={columnConfig.accessor}
            label={<Text size='sm'>{columnConfig.label}</Text>}
            mb='sm'
            style={{
              display:
                searchQuery !== ''
                  ? columnConfig.label.toLowerCase().includes(searchQuery)
                    ? 'block'
                    : 'none'
                  : 'block'
            }}
            onChange={(event) =>
              columnSelectionHandlers.setItemProp(
                index,
                'checked',
                event.currentTarget.checked
              )
            }
          />
        )
      );

      // Lodash's groupBy puts undefined items together under this key
      if (metricsGroupId === 'undefined') {
        return checkboxes;
      }

      const showGroup =
        searchQuery !== ''
          ? columnSelectionStateGroup.some(({ columnConfig }) =>
              columnConfig.label.toLowerCase().includes(searchQuery)
            )
          : true;

      const allChecked = columnSelectionStateGroup.every(
        ({ checked }) => checked
      );
      const indeterminate =
        columnSelectionStateGroup.some(({ checked }) => checked) && !allChecked;

      return (
        <Box
          key={metricsGroupId}
          mb='sm'
          style={{ display: showGroup ? 'block' : 'none' }}
        >
          <Checkbox
            checked={allChecked}
            indeterminate={indeterminate}
            label={
              <MetricsGroupLabel groupId={metricsGroupId as MetricsGroupId} />
            }
            mb='sm'
            onChange={() => {
              // Set the state of columns that belong to this metrics group
              columnSelectionHandlers.setState((current) =>
                current.map((entry) => {
                  if (entry.columnConfig.metricsGroupId === metricsGroupId) {
                    return { ...entry, checked: !allChecked };
                  } else {
                    return entry;
                  }
                })
              );
            }}
          />
          <Box pl='xl'>{checkboxes}</Box>
        </Box>
      );
    }
  );

  // At least one column is selected
  const canExport =
    exportMode === EXPORT_MODE.SELECT_COLUMNS
      ? columnSelectionState.some(({ checked }) => checked)
      : true;

  const handleExport = () => {
    // Only start the export process on the first click, wait for it to finish or fail
    if (!isExporting) {
      setIsExporting(true);

      const { startTime, endTime } = getTimeRange(lastUpdatedTime, timeRange);

      const sortSpec: components['schemas']['SortClause'][] = [];
      if (sortBy != null) {
        const columnConfig = Object.values(columnsConfig).find(
          (columnConfig) => columnConfig.accessor === sortBy
        );
        sortSpec.push(
          getSortBy({
            objectAccessor: columnConfig?.objectAccessor,
            filterType: columnConfig?.filterType,
            sortBy,
            sortDirection
          })
        );
      }

      const selectedColumns = columnSelectionState
        .filter(({ checked }) => checked)
        .map(({ columnConfig }) => {
          if (columnConfig.objectAccessor === 'feedback_ratings') {
            // Special case: Convert feedback column accessors to accepted format
            return `${columnConfig.accessor}_feedback_${columnConfig.label.toLowerCase().split(' ').join('_')}`;
          }
          return columnConfig.accessor;
        });

      exportData
        .mutateAsync({
          startTime,
          endTime,
          includeChains: true,
          filters,
          sortSpec,
          testSet: exportMode === EXPORT_MODE.TEST_SET,
          fileName,
          fileType,
          columns:
            exportMode === EXPORT_MODE.SELECT_COLUMNS
              ? selectedColumns
              : undefined
        })
        .finally(() => {
          setIsExporting(false);
        });
    }
  };

  return (
    <Paper data-testid='observe-export-modal'>
      <Paper pt='lg' px='lg'>
        <Group mb={8} style={{ justifyContent: 'space-between' }}>
          <Text c='gray.5' fw={400} size='sm'>
            Exporting
          </Text>
          <Text size='sm'>
            {selectedMetricsRows != null && selectedMetricsRows.length > 0
              ? 'Selected rows and subrows'
              : 'All rows in time range'}
          </Text>
        </Group>
        <Text c='gray.5' fw={400} mb={6} size='sm'>
          Export options
        </Text>
        <Radio.Group
          required
          display='block'
          value={exportMode}
          w='100%'
          onChange={(value) => setExportMode(value as ExportMode)}
        >
          <Group>
            <Radio
              className='box-outline'
              display='inline-block'
              label='Select columns'
              style={{ flex: 1 }}
              value={EXPORT_MODE.SELECT_COLUMNS}
            />
            <Radio
              className='box-outline'
              display='inline-block'
              label='A test set (of prompt inputs)'
              style={{ flex: 1 }}
              value={EXPORT_MODE.TEST_SET}
            />
          </Group>
        </Radio.Group>
      </Paper>
      {exportMode === EXPORT_MODE.TEST_SET && (
        <Box p='lg'>
          <Text c='gray.5' fw={400} size='sm'>
            The test set can be directly uploaded into Galileo Evaluate for
            experimentation.
          </Text>
        </Box>
      )}
      {exportMode === EXPORT_MODE.SELECT_COLUMNS && (
        <Box pt='lg' px='lg'>
          <Group justify='space-between'>
            <Text c='gray.5' fw={400} size='sm'>
              {`Exporting ${
                columnSelectionState.filter(({ checked }) => checked).length
              } column(s)`}
            </Text>
            <Group gap={0}>
              <Text c='gray.5' fw={400} mr='sm' size='sm'>
                Select:
              </Text>
              <Button
                h={45}
                p='sm'
                size='sm'
                variant='subtle'
                onClick={() => {
                  columnSelectionHandlers.setState((current) =>
                    current.map((entry) => ({
                      ...entry,
                      checked: true
                    }))
                  );
                }}
              >
                All
              </Button>
              <Button
                h={45}
                p='sm'
                size='sm'
                variant='subtle'
                onClick={() => {
                  columnSelectionHandlers.setState((current) =>
                    current.map((entry) => ({
                      ...entry,
                      checked: false
                    }))
                  );
                }}
              >
                None
              </Button>
              <Button
                h={45}
                p='sm'
                size='sm'
                variant='subtle'
                onClick={() => {
                  columnSelectionHandlers.setState((current) =>
                    current.map((entry) => ({
                      ...entry,
                      checked: !hiddenColumns.includes(
                        entry.columnConfig.accessor
                      )
                    }))
                  );
                }}
              >
                Visible columns
              </Button>
            </Group>
          </Group>
          <TextInput
            leftSection={<IconSearch size='1rem' />}
            placeholder='Search columns'
            styles={{ label: { color: theme.colors.gray[5] } }}
            onChange={(event) => setSearchQuery(event.target.value)}
          />
          <Box h={240} p='sm' style={{ overflowY: 'auto' }}>
            {allCheckboxes}
          </Box>
        </Box>
      )}
      <Divider />
      <Box p='lg'>
        <Group mb='sm'>
          <TextInput
            label='File Name'
            placeholder={defaultFileName}
            style={{ flex: 1 }}
            styles={{ label: { color: theme.colors.gray[5] } }}
            onChange={(event) => setCustomFileName(event.target.value)}
          />
          <Select
            data={FILE_TYPES}
            defaultValue={FILE_TYPES[0]}
            label='File Type'
            maw={120}
            styles={{
              label: { color: theme.colors.gray[5] },
              dropdown: { zIndex: Z_INDEX.MODALS + 1 }
            }}
            onChange={(value) =>
              value != null && setFileType(value as ExportFileType)
            }
          />
        </Group>
        <Text
          color='gray.5'
          fw={400}
          size='xs'
        >{`Exporting as "${fileName}.${fileType}"`}</Text>
      </Box>
      <Box bg='#F4F4F6' p='lg' style={{ borderTop: '1px solid #E6E6E6' }}>
        <Group style={{ justifyContent: 'flex-end' }}>
          <Button variant='default' onClick={closeAll}>
            Cancel
          </Button>
          <Button
            disabled={!canExport || isExporting}
            loading={isExporting}
            onClick={handleExport}
          >
            Export
          </Button>
        </Group>
      </Box>
    </Paper>
  );
};

export default ObserveExportModal;

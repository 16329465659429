import {
  Anchor,
  Box,
  Grid,
  Paper,
  Skeleton,
  Text,
  TextInput
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { IconPuzzleOff } from '@tabler/icons-react';

import { INTEGRATION_CARDS } from '@/core/constants/integrations.constants';
import { useIntegration } from '@/core/hooks/query-hooks/use-integration/use-integration';
import { Modals, useModals } from '@/core/hooks/use-modals/use-modals';

interface FormValues {
  cluster: string;
  databaseName: string;
  tableName: string;
}

const DatabricksExportForm = ({
  form
}: {
  form: UseFormReturnType<FormValues>;
}) => {
  const { data, isPending } = useIntegration('databricks');
  const { openModal } = useModals();

  if (isPending) {
    return <Skeleton data-testid='form-skeleton' height={40} mt='xs' />;
  }

  const config = INTEGRATION_CARDS.find((i) => i.id === 'databricks');

  return (
    <Box data-testid='databricks-form'>
      {data?.name ? (
        <>
          <TextInput
            autoFocus
            required
            label='Table Name'
            {...form.getInputProps('tableName')}
          />
        </>
      ) : (
        <Paper
          mt='xs'
          p='xs'
          style={({ colors }) => ({
            background: colors.contrast[8]
          })}
        >
          <Grid columns={24}>
            <Grid.Col span={3}>
              <Paper
                className='justify-center'
                p='xs'
                radius='md'
                style={({ colors }) => ({
                  background: colors.red[2],
                  width: 48,
                  height: 48
                })}
              >
                <IconPuzzleOff color='red' width={20} />
              </Paper>
            </Grid.Col>
            <Grid.Col span={21}>
              <>
                <Text fw={600}>Please set up the integration</Text>
                <Text c='gray.6' size='sm'>
                  You must set up the integration before using it.
                  <Anchor
                    color='brand.6'
                    fw={600}
                    mx={4}
                    size='sm'
                    onClick={() => openModal(Modals.INTEGRATIONS, { config })}
                  >
                    Click here
                  </Anchor>
                  to set up Databricks
                </Text>
              </>
            </Grid.Col>
          </Grid>
        </Paper>
      )}
    </Box>
  );
};

export default DatabricksExportForm;
